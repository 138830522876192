.container {
  h2 {
    margin-bottom: 16px;
    font-size: 22px;
    color: #000;
  }

  margin-top: 16px;
  padding: 10px 82px;

  min-width: 1080px;
}

.powered-by {
  span {
    display: block;
    font-size: 12px;
    color: #999999;
  }
}

.rec-carousel {
  div {
    &:focus {
      outline: none;
    }
  }
}

a {
  &:hover {
    text-decoration: none;
  }

  &:focus {
    outline: none;
  }
}

input::placeholder {
  font-size: 12px;
  color: #efefef;
}

button {
  &:focus {
    outline: none;
  }
}

::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.MuiTooltip-tooltip {
  font-size: 14px !important;
  text-transform: capitalize;
  font-weight: 800;
}
