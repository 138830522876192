.carousel {
  padding: 0;

  .rec-pagination {
    margin-top: 0px;
    margin-bottom: 5px;
  }

  [class*='rec-item-wrapper'] {
    padding: 0;
  }
  padding: 0 16px;

  .rec-arrow {
    outline: none !important;
    border: 1px dashed #1ea17f !important;
    color: #1ea17f !important;
    background: rgba(30, 161, 126, 0.15) !important;
    box-shadow: unset !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    font-weight: 800;

    &:hover:enabled {
      color: #fff !important;
      background: #1ea17f !important;
    }

    // &:disabled {
    //   visibility: hidden;
    // }
  }

  .rec-dot {
    &:hover,
    &:focus {
      background: #1ea17f;
      box-shadow: 0 0 1px 2px rgba(30, 161, 127, 0.5);
    }

    &.rec-dot_active {
      background: #1ea17f;
    }

    -webkit-transition: all 250ms ease;
    transition: all 250ms ease;
    border: none;
    margin: 5px;
    font-size: 1.3em;
    content: '';
    height: 14px;
    width: 7px;
    border: 1px solid #1ea17f;
    box-shadow: none;
    border-radius: 50%;
    outline: none;
  }
}
