.Header {
  background-color: white;
  height: 64px;
  position: fixed;
  width: 100%;
  display: flex;
  justify-content: space-between;
  z-index: 100;
  // box-shadow: 225px 1px 5px -2px rgba(0, 0, 0, 0.2);
  align-items: center;
  top:0;
  .right {
    margin-left: auto;
    display: flex;
    gap:16px;
    padding:10px;
    align-items: center;
  }
  .header--text {
    font-weight: 800;
    font-size: 16px;
  }
}

.Header-LogoWrapper {
  display: flex;
  max-width: 180px;
  width: 100%;
  height: 53px;
  // height: 100%;
  padding: 5px 18px;
  // background: #f3f3f3;
  margin-right: 24px;
  overflow: hidden;
  img {
    width: 100%; /* or any custom size */
    height: 100%;
    object-fit: contain;
  }
}
