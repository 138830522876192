@import './common.scss';
@import './global.scss';
html,
body {
  font-size: 14px;
  height: 100%;
  background-color: rgb(245, 245, 245);
  margin: 0;
  font-family: 'Lato', sans-serif;
}

code {
}

/* .slider {
	width: 50%;
	margin: 100px auto;
} */
