.layout--Container {
  display: flex;
  justify-content: space-between;
  > nav {
    position: fixed;
    width: 225px;
    height: 100%;
    padding-top: 65px;
    z-index: 99;
    background: #fff;
    // box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    padding-bottom: 10px;
    .sidebar--ul {
      list-style: none;
      margin: 0;
      padding: 0;
      padding-top: 24px;
      margin-bottom: 24px;
      height: 100%;
      border-top: 1px solid rgba(0, 0, 0, 0.1);
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      overflow-y: auto;
      ul {
        
        list-style: none;
        margin: 0;
        padding: 0;
      }
      .sidebar--li {
        border-left: 8px solid transparent;
        display: flex;
        justify-content: space-between;
        padding: 12px 24px 12px 12px;
        align-items: center;
        cursor: pointer;
        font-size: 18px;
        text-transform: lowercase;
        font-variant: small-caps;
        letter-spacing: 1px;
        transition: all ease 0.4s;
        svg {
          height: 24px;
          width: 24px;
        }
        &:hover {
          background: rgba(30, 161, 126, 0.1);
        }

        &.active {
          border-color: #1ea17f;
          color: #1ea17f;
          font-weight: 800;
        }
        &.submenu {
          padding-left: 36px;
          background: rgba(250, 250, 250, 1);
        }
      }
    }

    .sidebar-bottom--link {
      margin: auto 16px 16px 16px;
      font-size: 14px;
      padding: 8px;
      text-align: center;
      font-weight: 800;
      border-radius: 5px;
      border: 1px dashed #1ea17f;
      color: #1ea17f;
      background: rgba(30, 161, 126, 0.15);
    }
  }
  .main {
    margin: 72px 0 16px 0;
    margin-left: 225px;
    overflow: auto;
    padding-bottom: 200px;
    width: 100%;
    min-width: 960px;
    @media (max-width: 768px) {
      min-width: 1200px;
    }
  }
}
